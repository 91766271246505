<template>
  <form class="content-create-account-itens-body">
    <div class="row">
      <div class="content-elements">
        <div class="content-elements-header">
          <h2>{{ $t('register.personal_information') }}</h2>
        </div>
        <form
          ref="formRegister"
          @submit.prevent="handleSubmit"
          class="content-elements-body"
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="content-input">
                <label>{{ $t('register.name') }}*</label>
                <input
                  type="text"
                  name="name"
                  v-model="form.name"
                  value=""
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="content-input">
                <label>NIF</label>
                <input
                  type="text"
                  name="document"
                  v-model="form.document"
                  value=""
                  placeholder=""                  
                />
              </div>
            </div>
            <!-- <div class="col-lg-6">
              <div class="content-input">
                <label>{{ $t('register.birthday') }} *</label>
                <datetime
                  v-model="form.birthday"
                  :max-datetime="maxDate"
                  :required="true"
                ></datetime>
                <span class="help-block">Idade mínima de 18 anos.</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <label>{{ $t('register.gender') }} *</label>
                <div class="content-input-select">
                  <select name="gender_id" required>
                    <option value="">---</option>
                    <option value="1" :selected="form.gender_id == 1">
                      Masculino
                    </option>
                    <option value="2" :selected="form.gender_id == 2">
                      Feminino
                    </option>
                  </select>
                  <img
                    class="img-fluid"
                    src="@/assets/img/arrow-down.png"
                    alt="icon arrow down"
                    title="icon arrow down"
                  />
                </div>
              </div>
            </div> -->
            <div class="col-lg-6">
              <div class="content-input">
                <label>{{ $t('register.email_address') }}*</label>
                <input
                  type="email"
                  name="email"
                  v-model="form.email"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <label>{{ $t('register.telphone') }}*</label>
                <input type="hidden" name="mobile_ddi" value="351" />
                <input
                  type="text"
                  name="mobile"
                  v-model="form.mobile"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <label>{{ $t('register.password') }}*</label>
                <vue-password
                  v-model="password"
                  name="password"
                  :strength="strength"
                  @input="updateStrength"
                  :required="segment == 'create'"
                />
              </div>
            </div>
            <div class="col-lg-6">
              <div class="content-input">
                <label>{{ $t('register.password_confirm') }}*</label>
                <input type="password" name="password_confirmation" />
              </div>
            </div>
            <div v-if="Object.keys(user).length == 0" class="col-lg-12">
              <div class="content-input">
                <div class="start-field">
                  <input
                    type="checkbox"
                    name="condition"
                    value=""
                    placeholder=""
                    required
                  />
                  <label>
                    {{ $t('register.accept_terms') }}
                    <a v-b-modal.modalTerms>{{ $t('register.terms_and_conditions') }} </a> 
                    {{ $t('register.and_the') }}
                    <a v-b-modal.modalPolity>{{ $t('register.privacy_policy') }}</a>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <button
                v-if="segment == 'create'"
                type="submit"
                class="btn-create"
              >
                {{ $t('register.create_account') }}
              </button>
              <button v-else type="submit" class="btn-create">
                {{ $t('register.record') }}
              </button>
            </div>
            <div class="col-lg-12">
              <div class="btns-div">
                <router-link
                  :to="{ name: 'Home' }"
                  class="btn-continue-shopping"
                >
                  <img
                    class="img-fluid"
                    src="@/assets/img/arrow-left.png"
                    alt="arrow left"
                    title="arrow left"
                  />
                  {{ $t('btns_default.continue_shopping') }}
                </router-link>
                <template v-if="user && Object.keys(user).length > 0">
                  <router-link
                    :to="{ name: 'MyAccount' }"
                    class="btn-continue-shopping"
                  >
                  <svg
                    class="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="user"
                    role="img"
                  >
                    <path
                      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"
                    ></path>
                  </svg>
                    {{ $t('btns_default.my_account') }}
                  </router-link>
                </template>
                <template v-else>
                  <a @click="targetPage()" class="btn-continue-shopping">
                    {{ $t('btns_default.login_in') }}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="sign-in-alt"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"
                        class=""
                      ></path>
                    </svg>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
</template>

<script>
// import { Datetime } from "vue-datetime";
import VuePassword from "vue-password";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FormRegister",
  metaInfo: {
    title: "FormRegistro",
  },
  components: {
    // Datetime,
    VuePassword,
  },
  computed: {
    ...mapGetters("User", ["user"]),
  },
  data() {
    return {
      birthday: null,
      maxDate: this.$moment().subtract(18, "years").toISOString(),
      password: null,
      strength: 0,
      rulesPassword: [],
      segment: "create",
      message: {
        create: `Olá %name%, seja bem vindo e boas compras!`,
        update: "Registo atualizado com sucesso.",
      },
      form: {
        name: "",
        email: "",
        mobile: "",
        phone: "",
        birthday: "",
        document: "",
        gender_id: "",
      },
    };
  },
  methods: {
    ...mapActions("User", ["registerOrUpdate"]),
    async handleSubmit() {
      let formData = await this.$root.getAllData(this.$refs.formRegister);
      let segment = this.segment;

      formData.birthday = this.form.birthday;
      formData.segment = segment;
      console.log(this.form.birthday);

      if (this.$refs.formRegister.checkValidity()) {
        this.registerOrUpdate(formData).then((response) => {
          this.$alert(
            this.message[this.segment].replace("%name%", response.name)
          );
          localStorage.setItem("must_token", response.token);

          if (segment == "create" && this.$route.name != 'cart-checkout') {
            this.$router.push({ name: "Home" });
          }
        });
      } else {
        this.$refs.formRegister.reportValidity();
      }
    },
    updateStrength(password) {
      if (password.length > 3) {
        if (
          password.match(/[a-z]+/) &&
          this.rulesPassword.indexOf("letter_lower") < 0
        ) {
          this.strength += 1;
          this.rulesPassword.push("letter_lower");
        }
        if (
          password.match(/[A-Z]+/) &&
          this.rulesPassword.indexOf("letter_upper") < 0
        ) {
          this.strength += 1;
          this.rulesPassword.push("letter_upper");
        }
        if (
          password.match(/[0-9]+/) &&
          this.rulesPassword.indexOf("number") < 0
        ) {
          this.strength += 1;
          this.rulesPassword.push("number");
        }
        if (
          password.match(/[$@#&!]+/) &&
          this.rulesPassword.indexOf("special") < 0
        ) {
          this.strength += 1;
          this.rulesPassword.push("special");
        }
      }

      if (this.password == "") {
        this.strength = 0;
        this.rulesPassword = [];
      }
    },
    updateFormUser() {
      this.segment = "update";
      this.$emit("EventSegment", this.segment);

      var map = new Map(Object.entries(this.form));

      map.forEach((value, index) => {
        if (index == "birthday") {
          this.form[index] = this.$moment(this.user[index]).format(
            "YYYY-MM-DD"
          );
        } else {
          this.form[index] = this.user[index];
        }
      });
    },
    targetPage() {
      if (this.$route.name == "cart-checkout") {
        this.$emit("Login", 0);
      } else {
        this.$router.push({ name: "Login" });
      }
    },
  },
  watch: {
    user(promise) {
      this.updateFormUser();
    },
  },
  mounted() {
    if (Object.keys(this.user).length > 0) {
      this.updateFormUser();
    }
  },
};
</script>

<style>
</style>